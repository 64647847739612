<template>
    <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
    ></v-progress-circular>
</template>

<script>
export default {
    name: "SxLoadingCellRenderer",
};
</script>

<style>
</style>