var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-progress-circular", {
    attrs: { size: 70, width: 7, color: "primary", indeterminate: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }