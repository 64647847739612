const agGridLocaleDe = {
    // Set Filter
    selectAll: '(Alles auswählen)',
    selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
    searchOoo: 'Suchen...',
    blanks: '(Leerstellen)',
    noMatches: 'Keine Treffer',

    // Number Filter & Text Filter
    filterOoo: 'Filtern...',
    equals: 'ist gleich',
    notEqual: 'ist ungleich',
    empty: 'Auswählen',

    // Number Filter
    lessThan: 'Weniger als',
    greaterThan: 'Mehr als',
    lessThanOrEqual: 'Weniger oder gleich',
    greaterThanOrEqual: 'Mehr oder gleich',
    inRange: 'Wert zwischen',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

    // Text Filter
    contains: 'enthält',
    notContains: 'enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',

    // Date Filter
    dateFormatOoo: 'dd.mm.yyyy',

    // Filter Conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter Buttons
    applyFilter: 'Anwenden',
    resetFilter: 'Reset',
    clearFilter: 'Filter löschen',
    cancelFilter: 'Abbrechen',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Nummer Filter',
    dateFilter: 'Datums Filter',
    setFilter: 'Filter setzen',

    // Side Bar
    columns: 'Spalten',
    filters: 'Filter',

    // columns tool panel
    pivotMode: 'Pivot Modus',
    groups: 'Zeilengruppen',
    rowGroupColumnsEmptyMessage: 'Hierher ziehen, um Zeilengruppe zu setzen',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Hierher ziehen, um zu aggregieren',
    pivots: 'Spalten-Titel',
    pivotColumnsEmptyMessage: 'Hierher ziehen, um Spalten-Titel zu setzen',

    // Header of the Default Group Column
    group: 'Gruppe',

    // Other
    loadingOoo: 'Laden...',
    noRowsToShow: 'Keine Zeilen...',
    enabled: 'Aktiv',

    // Menu
    pinColumn: 'Spalte pinnen',
    pinLeft: 'Links pinnen',
    pinRight: 'Rechts pinnen',
    noPin: 'Nicht gepinnt',
    valueAggregation: 'Werteaggregation',
    autosizeThiscolumn: 'Spalte automatisch vergrößern',
    autosizeAllColumns: 'Alle Spalten automatisch vergrößern',
    groupBy: 'Gruppieren mit',
    ungroupBy: 'Entgruppieren mit',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alle expandieren',
    collapseAll: 'Alle schließen',
    copy: 'Kopieren',
    ctrlC: 'STRG + C',
    copyWithHeaders: 'Mit Spalten-Titel kopieren',
    paste: 'Einfügen',
    ctrlV: 'STRG + V',
    export: 'Exportieren',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'Keine',
    count: 'Anzahl',
    avg: 'Durchschnitt',
    filteredRows: 'Gefiltert',
    selectedRows: 'Ausgewählt',
    totalRows: 'Zeilenanzahl',
    totalAndFilteredRows: 'Zeilen',
    more: 'Mehr',
    to: 'zu',
    of: 'von',
    page: 'Seite',
    nextPage: 'nächste Seite',
    lastPage: 'letzte Seite',
    firstPage: 'erste Seite',
    previousPage: 'vorherige Seite',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Modus',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Spalte',
    groupedColumn: 'gruppiert',
    stackedColumn: 'gestapelt',
    normalizedColumn: '100% gestapelt',

    barChart: 'Leiste',
    groupedBar: 'gruppiert',
    stackedBar: 'gestapelt',
    normalizedBar: '100% gestapelt',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Einstellungen',
    data: 'Daten',
    format: 'Format',
    categories: 'Kategorien',
    defaultCategory: '(keine)',
    series: 'Serien',
    xyValues: 'X Y Werte',
    paired: 'Paired Modus',
    axis: 'Axe',
    navigator: 'Navigator',
    color: 'Farbe',
    thickness: 'Breite',
    xType: 'X Typ',
    automatic: 'Automatisch',
    category: 'Kategorie',
    number: 'Nummer',
    time: 'Zeit',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Breite',
    height: 'Höhe',
    length: 'Länge',
    padding: 'Seitenabstand',
    spacing: 'Abstand',
    chart: 'Chart',
    title: 'Titel',
    titlePlaceholder: 'Chart Titel - doppel klicken zum bearbeiten',
    background: 'Hintergrund',
    font: 'Font',
    top: 'Oben',
    right: 'Rechts',
    bottom: 'Unten',
    left: 'Links',
    labels: 'Labels',
    size: 'Größe',
    minSize: 'Minimum Größe',
    maxSize: 'Maximum Größe',
    legend: 'Legende',
    position: 'Position',
    markerSize: 'Marker Größe',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Abstand',
    itemSpacing: 'Element-Abstand',
    itemPaddingX: 'Element-Abstand X',
    itemPaddingY: 'Element-Abstand Padding Y',
    layoutHorizontalSpacing: 'horizontaler Abstand',
    layoutVerticalSpacing: 'vertikaler Abstand',
    strokeWidth: 'Stroke Breite',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Marker',
    shadow: 'Schatten',
    blur: 'Unschärfe',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Linienbreite',
    normal: 'Normal',
    bold: 'Fett',
    italic: 'Kursiv',
    boldItalic: 'Fett Kursiv',
    predefined: 'Vordefiniert',
    fillOpacity: 'Fülltransparenz',
    strokeOpacity: 'Linientransparenz',
    histogramBinCount: 'Bin count',
    columnGroup: 'Spalte',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Fläche',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Gruppiert',
    stackedColumnTooltip: 'Gestapelt',
    normalizedColumnTooltip: '100% Gestapelt',
    groupedBarTooltip: 'Gruppiert',
    stackedBarTooltip: 'Gestapelt',
    normalizedBarTooltip: '100% Gestapelt',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Fläche',
    stackedAreaTooltip: 'Gestapelt',
    normalizedAreaTooltip: '100% Gestapelt',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'Keine Daten vorhanden',
    pivotChartRequiresPivotMode: 'Pivot Chart benötigt Pivot Modus.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Chart herunterladen',

    // ARIA
    ariaHidden: 'versteckt',
    ariaVisible: 'sichtbar',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate:'indeterminate',
    ariaColumnSelectAll: 'Alles auswählen',
    ariaInputEditor: 'Eingabe-Editor',
    ariaDateFilterInput: 'Datum filtern',
    ariaFilterInput: 'Eingabe filtern',
    ariaFilterColumnsInput: 'Spalten filtern',
    ariaFilterValue: 'Wert filtern',
    ariaFilterFromValue: 'Wert filtern von',
    ariaFilterToValue: 'Wert filtern zu',
    ariaFilteringOperator: 'Filter-Operator',
    ariaColumnToggleVisibility: 'Spaltensichtbarkeit',
    ariaColumnGroupToggleVisibility: 'Spaltensichtbarkeit (Gruppe)',
    ariaRowSelect: 'Drücken Sie Leertaste, um die Zeile auszuwählen',
    ariaRowDeselect: 'Drücken Sie Leertaste, um die Zeile nicht mehr auszuwählen',
    ariaRowToggleSelection: 'Drücken Sie Leertaste, um den Zeilen-Auswahlmodus zu ändern',
    ariaRowSelectAll: 'Drücken Sie Leertaste, um alle Zeilen auszuwählen',
    ariaSearch: 'Suchen',
    ariaSearchFilterValues: 'Filter-Werte suchen'
}

export default agGridLocaleDe